
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'NavMicro',

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('menu', ['menu']),
    ...mapGetters('menu', ['getMenuWithDepth']),

    menuItems() {
      return this.getMenuWithDepth(2).filter(
        (item) =>
          !['terms of use', 'about'].includes(
            item.page.menu_title.toLowerCase()
          )
      )
    },
  },

  async created() {
    if (!this.menu) await this.getMenu()
  },

  methods: {
    ...mapActions('menu', ['getMenu']),
  },
}
