
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showDropdown: false,
    }
  },

  computed: {
    ...mapGetters('user', ['isAdmin']),
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: 'auth-login' })
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
  },
}
