
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Hamburger',

  computed: {
    ...mapState('ui', ['navIsOpen']),

    computedClass() {
      return {
        'hamburger--active': this.navIsOpen,
      }
    },
  },

  methods: {
    ...mapActions('ui', ['toggleNav']),

    onClick() {
      this.toggleNav()
    },
  },
}
