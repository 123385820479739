
export default {
  name: 'ButtonEscape',

  mounted() {
    window.addEventListener('keyup', this.keyupHandler)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler)
  },

  methods: {
    async clearCaches() {
      if (process.server) return

      const workbox = await window.$workbox

      if (!workbox) return

      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName)
        })
      })
    },

    gtfo() {
      document.body.style.display = 'none'

      if (localStorage.getItem(this.$config.persistedStateKey)) {
        localStorage.removeItem(this.$config.persistedStateKey)
      }

      this.clearCaches()

      const randomSite = () =>
        this.$config.escapeUrls[
          Math.floor(Math.random() * this.$config.escapeUrls.length)
        ]

      window.open(randomSite())
      window.location.replace(randomSite())
    },

    keyupHandler(event) {
      if (event.key === 'Delete' || event.keyCode === 46) {
        this.gtfo()
      }
    },
  },
}
