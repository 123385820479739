const defaultState = () => ({
  customer: null,
  orders: [],
  order: null,
  shippingAddress: null,
  isShippingAddressValid: false,
  sendEmailConfirmation: false,
  email: null,
})

export const state = defaultState

export const mutations = {
  SET_CUSTOMER(state, payload) {
    state.customer = payload
  },

  SET_ORDERS(state, payload) {
    state.orders = payload
  },

  SET_ORDER(state, payload) {
    state.order = payload
  },

  SET_SHIPPING_ADDRESS(state, payload) {
    state.shippingAddress = payload
  },

  SET_EMAIL(state, payload) {
    state.email = payload
  },

  SET_SHIPPING_ADDRESS_VALID(state, payload) {
    state.isShippingAddressValid = payload
  },

  SET_SEND_EMAIL_CONFIRMATION(state, payload) {
    state.sendEmailConfirmation = payload
  },

  RESET_ORDER(state) {
    state.order = null
  },

  RESET_STATE(state) {
    const initial = defaultState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const getters = {
  customer: (state) => state.customer,
  getOrder: (state) => state.order,
  getOrderId: (state) => (state.order ? state.order.id : null),
  getShippingAddress: (state) => state.shippingAddress,
  getSendEmailConfirmation: (state) => state.sendEmailConfirmation,
}

export const actions = {
  async setCustomer({ commit, dispatch }) {
    if (!process.client) return

    const localStorageKey = '1800-transactCustomerId'
    const existingCustomerId = localStorage.getItem(localStorageKey)
    try {
      let customer
      if (this.$auth.loggedIn) {
        try {
          customer = await this.$api.ltng.transact.customers.getCustomer(
            this.$auth.user.sub,
            {
              params: {
                use_origin_id: true,
              },
            }
          )
        } catch (error) {
          if (error && error.response && error.response.status === 404) {
            if (existingCustomerId) {
              customer = await this.$api.ltng.transact.customers.updateCustomer(
                existingCustomerId,
                {
                  origin_id: this.$auth.user.sub,
                }
              )
            } else {
              customer = await this.$api.ltng.transact.customers.createCustomer(
                {
                  origin_id: this.$auth.user.sub,
                }
              )
            }
          }
        }
      } else if (existingCustomerId) {
        customer = {
          id: existingCustomerId,
        }
      } else {
        customer = await this.$api.ltng.transact.customers.createCustomer({
          account_id: this.$config.ltngId,
        })
        localStorage.setItem(localStorageKey, customer.id)
      }

      await commit('SET_CUSTOMER', customer)
      dispatch('setOrders')
      return Promise.resolve(customer)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async setOrders({ commit, dispatch, state }) {
    if (!state.customer) await dispatch('setCustomer')

    let orders = []
    if (this.$auth.loggedIn && state.customer && state.customer.id) {
      orders = await this.$api.ltng.transact.orders.getOrders({
        params: {
          customer_id: state.customer.id,
        },
      })
    }

    await commit('SET_ORDERS', orders)
  },

  async setShippingAddress({ commit }, payload) {
    await commit('SET_SHIPPING_ADDRESS', payload)
    return Promise.resolve(payload)
  },

  async setEmail({ commit }, payload) {
    await commit('SET_EMAIL', payload)
    return Promise.resolve(payload)
  },

  async setShippingAddressValid({ commit }, payload) {
    await commit('SET_SHIPPING_ADDRESS_VALID', payload)
    return Promise.resolve(payload)
  },

  async setSendEmailConfirmation({ commit }, payload) {
    await commit('SET_SEND_EMAIL_CONFIRMATION', payload)
  },

  async createOrder({ commit }, payload) {
    try {
      const order = await this.$api.ltng.transact.orders.createOrder(payload)

      commit('SET_ORDER', order)
      return Promise.resolve(order)
    } catch (error) {
      this.$bugsnag.notify(error)
      return Promise.reject(error)
    }
  },

  async resetOrder({ commit }) {
    await commit('RESET_ORDER')
  },

  async checkout({ commit, dispatch, state, rootGetters }) {
    try {
      const orderPayload = {
        customer_id: state.customer.id,
        email_receipt: state.sendEmailConfirmation,
        checkout: true,
        addresses: [{ ...state.shippingAddress, type: 'shipping' }],
        items: rootGetters['cart/getCartItems'].map((item) => ({
          item_id: item.id,
          quantity: item.quantity,
        })),
      }
      if (state.sendEmailConfirmation && state.email) {
        orderPayload.email = state.email
      }

      const order = await dispatch('createOrder', {
        ...orderPayload,
      })

      commit('SET_ORDER', order)

      return Promise.resolve(order)
    } catch (error) {
      let message =
        'There was a problem submitting your order. Please try again.'

      if (error.response?.data) {
        try {
          message = JSON.parse(error.response.data).msg
        } catch {}
      }

      this.$toast.error(message)
      this.$bugsnag.notify(error)
      return Promise.reject(error)
    }
  },

  async resetState({ commit }) {
    await commit('RESET_STATE')
  },
}
