
import { mapState, mapActions } from 'vuex'
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  name: 'Header',

  components: {
    SlideYDownTransition,
  },

  data() {
    return {
      navLinks: [
        {
          to: '/information-for-victims',
          label: 'Information for Victims',
        },
        { to: '/resources', label: 'Resource Directory' },
        { to: '/publications', label: 'Publications' },
      ],
    }
  },

  computed: {
    ...mapState('ui', ['navIsOpen']),
  },

  methods: {
    ...mapActions('ui', ['toggleNav']),

    onSearch() {
      this.$router.push('/search')
    },
  },
}
