
import config from '@/config'

export default {
  name: 'SocialLinks',
  computed: {
    links() {
      return config.socialNetworks
    },
  },
}
