
import { SlideYDownTransition, SlideXLeftTransition } from 'vue2-transitions'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Default',

  components: {
    SlideYDownTransition,
    SlideXLeftTransition,
  },

  data() {
    return {
      externalLinkModal: {
        show: false,
        url: '',
      },

      userPollInterval: null,
    }
  },

  computed: {
    ...mapState('user', ['disclaimerAccepted']),
    ...mapGetters('user', ['isProvider']),
  },

  created() {
    this.getCounty()
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)

    this.$nuxt.$on('global:external-link', (event) => {
      this.externalLinkModal = {
        show: true,
        ...event,
      }
    })

    if (this.$auth.loggedIn && !this.isProvider) {
      this.userPollInterval = setInterval(this.getUser, 10000)
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)

    if (this.userPollInterval) clearInterval(this.userPollInterval)
  },

  methods: {
    ...mapActions('general', ['getCounty']),
    ...mapActions('user', ['setDisclaimerAccepted', 'getUser']),

    onScroll(event) {
      if (['sm'].includes(this.$mq)) {
        // Do not show on mobile screens
      } else if (['md', 'lg'].includes(this.$mq)) {
        this.showEscapeButton = window.scrollY > 176
      } else if (['xl'].includes(this.$mq)) {
        this.showEscapeButton = window.scrollY > 134
      } else {
        this.showEscapeButton = window.scrollY > 20
      }
    },
  },
}
