export default function (axios) {
  return {
    getAll(params = {}) {
      return axios.get('/modules/access-requests', params)
    },

    update(payload, params) {
      return axios.put('/modules/access-requests', payload, params)
    },
  }
}
