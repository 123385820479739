
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Searchbox',
  props: {
    name: {
      type: String,
      default: 'search',
    },
    classname: {
      type: String,
      default: 'inputclass',
    },
    arialabel: {
      type: String,
      default: 'Label',
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    errortext: {
      type: String,
      default: 'Error',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('ui', ['search']),
  },

  methods: {
    ...mapActions('ui', ['setSearchQuery']),

    onInput(event) {
      this.setSearchQuery(event.target.value)
    },

    onSubmit(event) {
      this.$emit('search', this.search.query)
    },
  },
}
