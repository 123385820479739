
export default {
  name: 'Error',

  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  computed: {
    computedErrorTitle() {
      switch (this.error.statusCode) {
        case 404:
          return 'Page not found'
        case 500:
        default:
          return 'An error occurred'
      }
    },
  },
}
