
import { mapActions, mapGetters, mapState } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions/dist/vue2-transitions.cjs'

export default {
  name: 'MenunavFull',

  components: {
    SlideYUpTransition,
  },

  computed: {
    ...mapState('menu', ['menu']),
    ...mapGetters('menu', ['getMenuWithDepth']),

    menuItems() {
      return this.getMenuWithDepth(2).filter((item) =>
        [
          'information for victims',
          'resources',
          'publications',
          'legal center',
          'service provider portal',
          'about',
        ].includes(item.page.menu_title.toLowerCase())
      )
    },
  },

  created() {
    if (!this.menu) this.getMenu()
  },

  methods: {
    ...mapActions('ui', ['closeNav']),
    ...mapActions('menu', ['getMenu']),

    isActive(index) {
      return index === this.activeIndex
    },

    toggleActive(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
        return
      }

      this.activeIndex = index
    },
  },
}
