export const addMetaTags = (options) =>
  Object.keys(options)
    .map((option) => {
      return [
        {
          hid: `twitter:${option}`,
          name: `twitter:${option}`,
          content: options[option],
        },
        {
          hid: `og:${option}`,
          name: `og:${option}`,
          content: options[option],
        },
        {
          hid: option,
          name: option,
          content: options[option],
        },
      ]
    })
    .flat()
