export default function (axios) {
  return {
    getCategories() {
      return axios.get('categories/')
    },

    // getCronCounter() {
    //   return axios.get('croncounter.1800victims.workers.dev/')
    // },

    getCounty(params) {
      return axios.get(`counties?limit=1000`, { params })
    },

    getResourcesByCat(params) {
      return axios.get(`resources`, { params })
    },

    getResourcesByID(id, params = {}) {
      return axios.get(`resources/${id}`, params)
    },

    getResourcesByCounty(id, params) {
      return axios.get(`/pages/${id}`, params)
    },

    getResourcesByZipcode(id, params) {
      return axios.get(`/pages/${id}`, params)
    },

    getResourcesByCordinates(lon, lat) {
      return axios.get(`resources?lat=${lat}&lon=${lon}`)
    },

    getResourcesByCordCat(id, params) {
      return axios.get(`/pages/${id}`, params)
    },

    getResourcesBykeyCordCat(id, params) {
      return axios.get(`/pages/${id}`, params)
    },

    $client: axios,
  }
}
