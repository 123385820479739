
import logoSVG from '~/assets/svg/logo.svg?inline'

export default {
  name: 'Logo',
  components: {
    logoSVG,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
}
