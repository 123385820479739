const defaultState = () => ({
  items: [],
  pendingItems: [],
  step: 1,
  maxQuantity: 50,
})

export const state = defaultState

export const getters = {
  getCartItems: (state) => state.items,
  getCartItemCount: (state) => state.items.length,
  getCartStep: (state) => state.step,
  pendingItems: (state) => state.pendingItems,
  pendingItemCount: (state) => state.pendingItems.length,
  isItemInCart: (state) => (item) => {
    return state.items.some((_item) => {
      if (_item.id === item.id) {
        return true
      } else if (item.variants) {
        return item.variants.some((variant) => _item.id === variant.id)
      }
    })
  },
  isItemPending: (state) => (item) => {
    return state.pendingItems.some((pendingItem) => {
      if (pendingItem.id === item.id) {
        return true
      } else if (item.variants) {
        return item.variants.some((variant) => pendingItem.id === variant.id)
      }
    })

    // return state.pendingItems.find((pendingItem) =>
    //   pendingItem.id.startsWith(item.id)
    // )
  },
  getCartItemById: (state) => (id) =>
    state.items.find((item) => item.id === id),
}

export const mutations = {
  ADD_CART_ITEM(state, { item, quantity }) {
    state.items.push({
      ...item,
      quantity,
    })
  },

  REMOVE_CART_ITEM(state, item) {
    state.items = state.items.filter((_item) => {
      return _item.id !== item.id
    })

    this.$toast.success(`${item.title} has been removed from your cart.`)
  },

  UPDATE_ITEM_QUANTITY(state, { item, quantity }) {
    const itemIndex = state.items.findIndex((_item) => _item.id === item.id)
    if (itemIndex === -1) return

    state.items = [
      ...state.items.slice(0, itemIndex),
      {
        ...state.items[itemIndex],
        quantity,
      },
      ...state.items.slice(itemIndex + 1),
    ]
  },

  ADD_PENDING_ITEM(state, { item, quantity }) {
    state.pendingItems.push({
      ...item,
      quantity,
    })
  },

  REMOVE_PENDING_ITEM(state, item) {
    state.pendingItems = state.pendingItems.filter(
      (pendingItem) => !pendingItem.id.startsWith(item.id)
    )
  },

  CLEAR_PENDING_ITEMS(state) {
    state.pendingItems = []
  },

  CLEAR_ITEMS(state) {
    state.items = []
  },

  ADD_CART_STEP(state) {
    state.step++
  },

  REMOVE_CART_STEP(state) {
    state.step--
  },

  RESET_CART_STEP(state) {
    state.step = 1
  },

  RESET_STATE(state) {
    const initial = defaultState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
  RESET_MAX_QUANTITY(state) {
    // if (state.maxQuantity === 200) state.maxQuantity = 50
  },
}

export const actions = {
  async resetMaxQty({ commit }) {
    await commit('RESET_MAX_QUANTITY')
  },

  async addCartStep({ commit }) {
    await commit('ADD_CART_STEP')
  },

  async removeCartStep({ commit }) {
    await commit('REMOVE_CART_STEP')
  },

  async resetCartStep({ commit }) {
    await commit('RESET_CART_STEP')
  },

  async addCartItem({ commit, state }, { item, quantity }) {
    // if (quantity > state.maxQuantity) {
    //   throw new Error(
    //     `There is currently a limit of ${state.maxQuantity} copies per publication.`
    //   )
    // }

    await commit('ADD_CART_ITEM', { item, quantity })

    return Promise.resolve(item)
  },

  async removeCartItem({ commit }, item) {
    await commit('REMOVE_CART_ITEM', item)
  },

  async updateItemQuantity({ commit, state }, { item, quantity }) {
    // if (quantity > state.maxQuantity) {
    //   throw new Error(
    //     `There is currently a limit of ${state.maxQuantity} copies per publication.`
    //   )
    // }

    try {
      await commit('UPDATE_ITEM_QUANTITY', { item, quantity })

      const updatedItem = state.items.find((_item) => _item.id === item.id)

      return Promise.resolve(updatedItem)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async addPendingItem({ commit }, { item, quantity }) {
    await commit('ADD_PENDING_ITEM', { item, quantity })
  },

  async removePendingItem({ commit }, item) {
    await commit('REMOVE_PENDING_ITEM', item)
  },

  async clearPendingItems({ commit }) {
    await commit('CLEAR_PENDING_ITEMS')
  },

  async clearItems({ commit }) {
    await commit('CLEAR_ITEMS')
  },

  async commitPendingItems({ commit, state }) {
    await Promise.all(
      state.pendingItems.map(
        async (item) => await commit('ADD_CART_ITEM', item)
      )
    )
    await commit('CLEAR_PENDING_ITEMS')
  },

  async resetState({ commit }) {
    return await commit('RESET_STATE')
  },
}
