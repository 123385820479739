const defaultState = () => ({
  isAuthenticated: false,
  user: {},
  waitingForOTP: false,
  disclaimerAccepted: false,
})

export const state = defaultState

export const getters = {
  isProvider: (state, getters, rootState) =>
    rootState.auth &&
    rootState.auth.user &&
    Array.isArray(rootState.auth.user.roles) &&
    !!rootState.auth.user.roles.find(
      ({ role }) => role.toLowerCase() === 'provider'
    ),

  isAdmin: (state, getters, rootState) =>
    rootState.auth &&
    rootState.auth.user &&
    Array.isArray(rootState.auth.user.roles) &&
    !!rootState.auth.user.roles.find(
      ({ role }) => role.toLowerCase() === 'admin'
    ),

  getUserProperty:
    ({ user }) =>
    (key) => {
      const property =
        user.properties &&
        user.properties.find((property) => property.key === key)

      if (property) return property.value

      return null
    },
}

// MUTATIONS
export const mutations = {
  user(state, user) {
    state.isAuthenticated = !!user
    state.user = user || {}
  },

  SET_USER(state, user) {
    state.user = user
  },

  SET_WAITING_OTP(state, payload) {
    state.waitingForOTP = payload
  },

  SET_DISCLAIMER_ACCEPTED(state, payload) {
    state.disclaimerAccepted = payload
  },

  UPDATE_USER(state, payload) {},

  LOGOUT_USER(vuexState) {
    vuexState = {
      ...state(),
      user: state().user,
      disclaimerAccepted: vuexState.disclaimerAccepted,
    }
  },

  RESET_STATE(state) {
    state = Object.assign({}, defaultState())
  },
}

// ACTIONS
export const actions = {
  async getUser({ commit }) {
    try {
      const wasProvider = (() => this.getters['user/isProvider'])()

      const result = await this.$api.ltng.users.getUsers()
      await this.$auth.setUser(result.data)
      await commit('SET_USER', this.$auth.user)

      const isProvider = this.getters['user/isProvider']

      if (wasProvider === false && isProvider === true) {
        this.$toast.success(
          'Your Service Provider access request has been approved',
          {
            action: [
              {
                text: 'View Portal',
                push: {
                  to: '/legal-center/service-provider-portal',
                },
              },
            ],
          }
        )
      }
    } catch {}
  },

  async setUser({ commit }, user) {
    await commit('SET_USER', user)
  },

  async sendPasswordlessOTP({ commit }, email) {
    const authAxios = this.$axios.create({
      baseURL: this.$config.auth0.domain,
    })

    try {
      const { _id } = await authAxios.$post(
        `/passwordless/start`,
        {
          client_id: this.$config.auth0.clientId,
          connection: 'email',
          email,
          send: 'code',
        },
        {
          baseURL: `https://${this.$config.auth0.domain}`,
        }
      )

      if (_id) commit('SET_WAITING_OTP', true)
    } catch (error) {
      if (error.response && error.response.data.error_description) {
        this.$toast.error(error.response.data.error_description)
      }
    }
  },

  async getPasswordlessTokensAndSignin({ dispatch }, { email, code }) {
    const authAxios = this.$axios.create({
      baseURL: `https://${this.$config.auth0.domain}`,
    })

    try {
      const { access_token: accessToken, refresh_token: refreshToken } =
        await authAxios.$post(
          `/oauth/token`,
          {
            client_id: this.$config.auth0.clientId,
            grant_type: this.$config.auth0.grantType,
            audience: this.$config.auth0.audience,
            scope: this.$config.auth0.scope,
            username: email,
            realm: 'email',
            otp: code,
          },
          {
            baseURL: `https://${this.$config.auth0.domain}`,
          }
        )

      await dispatch('signInUserWithTokens', {
        accessToken,
        refreshToken,
      })
      dispatch('setWaitingOTP', false)
    } catch (error) {
      if (error.response && error.response.data.error_description)
        this.$toast.error(error.response.data.error_description)
    }
  },

  async signInUserWithTokens({ dispatch }, { accessToken, refreshToken }) {
    this.$auth.strategy.token.set(accessToken)
    this.$auth.strategy.refreshToken.set(refreshToken)
    this.$api.ltng.$client.setToken(accessToken, 'Bearer')
    this.$api.resources.$client.setToken(accessToken, 'Bearer')

    try {
      await dispatch('getUser')
      await dispatch('transact/setCustomer', null, { root: true })
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async setWaitingOTP({ commit }, payload) {
    await commit('SET_WAITING_OTP', payload)
  },

  async setDisclaimerAccepted({ commit }, payload) {
    await commit('SET_DISCLAIMER_ACCEPTED', payload)
  },

  async updateUser({ commit }, payload) {
    const result = await this.$api.ltng.users.putUsers(payload.id, payload)
    return result
  },

  async loginWithEmailAndPassword({ dispatch }, { email, password }) {
    try {
      const response = await this.$auth.request({
        method: 'post',
        url:
          'https://' +
          this.$auth.strategy.options.domain +
          this.$auth.strategy.options.endpoints.authorization,
        data: {
          client_id: this.$auth.strategy.options.clientId,
          scope: this.$auth.strategy.options.scope,
          grant_type: this.$auth.strategy.options.grantType,
          username: email,
          password,
        },
      })

      await dispatch('signInUserWithTokens', {
        accessToken: response.data.id_token,
        refreshToken: response.data.refresh_token,
      })

      this.$api.ltng.analytics.record({ auth: 'login' })

      this.$toast.success('You are logged in')

      return response
    } catch (e) {
      this.$toast.error(
        e.response?.data?.msg ||
          e.response?.data?.error_description ||
          'There was a problem logging you in'
      )

      return Promise.reject(e)
    }
  },

  async registerWithEmailAndPassword({ dispatch }, { email, password }) {
    try {
      return await this.$auth.request({
        method: 'post',
        url:
          'https://' +
          this.$auth.strategy.options.domain +
          '/dbconnections/signup',
        data: {
          client_id: this.$auth.strategy.options.clientId,
          scope: this.$auth.strategy.options.scope,
          connection: this.$config.auth0.passwordConnection,
          email,
          password,
        },
      })
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async sendPasswordReset({ dispatch }, email) {
    try {
      const response = await this.$auth.request({
        method: 'post',
        url:
          'https://' +
          this.$auth.strategy.options.domain +
          '/dbconnections/change_password',
        data: {
          client_id: this.$auth.strategy.options.clientId,
          email,
          connection: this.$config.auth0.passwordConnection,
        },
      })

      return response
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async logout({ commit }) {
    await commit('LOGOUT_USER')
    await this.$auth.logout()
  },

  async resetState({ commit }) {
    return await commit('RESET_STATE')
  },
}
