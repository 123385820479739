
export default {
  props: {
    href: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      intervalId: null,
      timer: 5, // Timer in miliseconds
    }
  },

  mounted() {
    this.intervalId = setInterval(() => {
      if (this.timer === 1) {
        clearInterval(this.intervalId)
        document.location.href = this.href
      }
      this.timer--
    }, 1000)
  },

  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId)
  },
}
