
import config from '@/config'
import CVRCLogoSVG from '~/assets/svg/cvrc-logo.svg?inline'
import VLRCLogoSVG from '~/assets/svg/vlrc-logo.svg?inline'

export default {
  name: 'Footer',
  components: {
    CVRCLogoSVG,
    VLRCLogoSVG,
  },
  props: {
    pages: {
      type: Array,
      default: () => config.routes,
    },
  },
}
