
export default {
  name: 'ButtonLiveChat',

  props: {
    text: {
      type: String,
      default: 'Button Text',
    },
    primary: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    peach: {
      type: Boolean,
    },
    abort: {
      type: Boolean,
    },
    altgreen: {
      type: Boolean,
    },
    grey: {
      type: Boolean,
    },
    black: {
      type: Boolean,
    },
    danger: {
      type: Boolean,
    },
    corpdarkblue: {
      type: Boolean,
    },
    corplightblue: {
      type: Boolean,
    },
    corpmediumblue: {
      type: Boolean,
    },
    corplightteal: {
      type: Boolean,
    },
    corpdarkteal: {
      type: Boolean,
    },
    corppeach: {
      type: Boolean,
    },
    corpdarkorange: {
      type: Boolean,
    },
    corplightorange: {
      type: Boolean,
    },
  },

  data() {
    return {
      chatUrl:
        'https://na0messaging.icarol.com/ConsumerRegistration.aspx?org=84268&pid=104&cc=en-US',
      popupWindow: null,
    }
  },

  computed: {
    computedClass() {
      return {
        'btn--primary': this.primary,
        'btn--secondary': this.secondary,
        'btn--transparent': this.transparent,
        'btn--peach': this.peach,
        'btn--disabled': this.disabled,
        'btn--outlined': this.outlined,
        'btn--abort': this.abort,
        'btn--altgreen': this.altgreen,
        'btn--black': this.black,
        'btn--danger': this.danger,
        'btn--corp-darkblue': this.corpdarkblue,
        'btn--corp-mediumblue': this.corpmediumblue,
        'btn--corp-lightblue': this.corplightblue,
        'btn--corp-darkteal': this.corpdarkteal,
        'btn--corp-peach': this.corppeach,
        'btn--corp-darkorange': this.corpdarkorange,
        'btn--corp-lightorange': this.corplightorange,
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler)
  },

  methods: {
    onClick(e) {
      this.$emit('click', e)
      this.openChatPopup()
    },

    openChatPopup() {
      this.popupWindow = window.open(
        this.chatUrl,
        'popUpWindow',
        'height=550,width=375,right=10,bottom=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
      )

      this.popupWindow.focus()
    },

    keyupHandler(event) {
      if (event.key && event.key.toLowerCase() === 'home') {
        this.openChatPopup()
      }
    },
  },
}
