
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  components: {
    SlideYUpTransition,
    ChevronIcon: () => import('@/assets/svg/chevron.svg?inline'),
  },

  props: {
    page: {
      type: Object,
      required: true,
    },

    depth: {
      type: Number,
      default: NaN,
    },

    children: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isActive: false,
    }
  },
}
