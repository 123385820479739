
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: 'Button Text',
    },
    arialabel: {
      type: String,
      default: '',
    },
  },

  computed: {},

  methods: {
    onClick(e) {
      this.$emit('click', e)
    },
  },
}
