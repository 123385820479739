export default function (axios) {
  return {
    getOrder(id, params = {}) {
      return axios
        .get(`/modules/transact/orders/${id}`, params)
        .then((res) => res.data)
    },

    getOrders(params) {
      return axios
        .get('/modules/transact/orders', params)
        .then((res) => res.data.results)
    },

    createOrder(body, params = {}) {
      return axios
        .post('/modules/transact/orders', body, params)
        .then((res) => res.data)
    },

    updateOrder(id, body, params = {}) {
      return axios
        .put(`/modules/transact/orders/${id}`, body, params)
        .then((res) => res.data)
    },

    getOrderAddresses(params) {
      return axios
        .get('/modules/transact/order-addresses', params)
        .then((res) => res.data.results)
    },

    createOrderAddress(body, params = {}) {
      return axios
        .post('/modules/transact/order-addresses', body, params)
        .then((res) => res.data)
    },

    getShippingMethods(params = {}) {
      return axios
        .get('/modules/transact/shipping-methods', params)
        .then((res) => res.data)
    },
  }
}
