
export default {
  name: 'InputCheckbox',
  props: {
    id: {
      type: String,
      default: 'id',
    },
    name: {
      type: String,
      default: 'name',
    },
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: [Boolean, String],
      required: true,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
