import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'

export default function (context, inject) {
  Vue.use(Vuelidate)

  inject('validationMessage', (field, options = {}) =>
    validationMessage(
      {
        required: () => 'This field is required',
        email: () => 'Must be a valid email',
        mustAccept: () => 'Must accept before continuing',
        minLength: ({ $params }) =>
          `Must be at least ${$params.minLength.min} characters`,
        maxLength: ({ $params }) =>
          `Must be no more than ${$params.maxLength.max} characters`,
        alphaNum: () => 'Must be only alphanumeric characters',
        alpha: () => 'Must be only aphabet characters',
        numeric: () => 'Must be only numeric characters',
        sameAsPassword: (value) => 'Passwords do not match',
        url: () => 'Must be a valid url',
      },
      options
    )(field)
  )
}
