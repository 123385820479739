
import googlePlaySVG from '~/assets/svg/GooglePlay.svg?inline'
import appStoreSVG from '~/assets/svg/AppStore.svg?inline'

export default {
  name: 'StoreLinks',
  components: {
    googlePlaySVG,
    appStoreSVG,
  },
}
