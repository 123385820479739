
export default {
  data() {
    return {
      accepted: false,
    }
  },

  methods: {
    setDisclaimerAccepted() {
      this.$emit('close')
      this.$emit('accepted')
    },
  },
}
