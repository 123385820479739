export default async (context) => {
  if (process.server) return

  const workbox = await window.$workbox

  if (!workbox) {
    return
  }

  workbox.addEventListener('activated', (event) => {
    console.info('New Service Worker activating...')
    clearCache()
    resetStore(context.store)
  })

  workbox.addEventListener('installed', (event) => {})
}

async function clearCache() {
  // eslint-disable-next-line no-console
  console.info('Clearing cache...')

  const cacheAllowList = []

  const keys = await caches.keys()

  return Promise.all(
    keys.map((key) => {
      if (!cacheAllowList.includes(key)) {
        return caches.delete(key)
      }

      return Promise.resolve()
    })
  )
}

async function resetStore(store) {
  // eslint-disable-next-line no-console
  console.info('Resetting vuex state...')
  return await store.dispatch('reset')
}
