import products from './products'
import customers from './customers'
import orders from './orders'

export default function (axios) {
  return {
    orders: orders(axios),
    customers: customers(axios),
    products: products(axios),
  }
}
