export const state = () => ({
  zip: '',
  county: '',
})

export const mutations = {
  SET_ZIP(state, payload) {
    state.zip = payload
  },

  SET_COUNTY(state, payload) {
    state.county = payload
  },
}

export const actions = {
  async setZip({ commit }, payload) {
    if (isNaN(payload)) return

    commit('SET_ZIP', payload)
    return await Promise.resolve(payload)
  },

  async setCounty({ commit }, payload) {
    commit('SET_COUNTY', payload)
    return await Promise.resolve(payload)
  },

  async clearZip({ commit }) {
    commit('SET_ZIP', '')
    return await Promise.resolve()
  },
}
