const defaultState = () => ({
  menu: null,
})

export const state = defaultState

export const getters = {
  getMenuWithDepth: (state) => (depth) => {
    if (!state.menu) return []

    const getLevel = (items) => {
      return items
        .filter((item) => item.depth <= depth)
        .map((item) => {
          if (item.depth <= depth) {
            return {
              ...item,
              children: item.children ? getLevel(item.children) : [],
            }
          }
        })
    }

    return getLevel(state.menu, depth)
  },

  getMenuWithOmitted:
    (state) =>
    (omittedTitles = []) => {
      if (!state.menu) return []
      return state.menu.filter(
        (item) =>
          !omittedTitles
            .map((title) => title.toLowerCase())
            .includes(
              item.page.menu_title.toLowerCase() ||
                item.page.title.toLowerCase()
            )
      )
    },

  getMenuAtParent: (state) => (options) => {
    if (!state.menu) return {}

    return state.menu.find((item) => {
      if (options.furl && options.furl === item.page.furl) return item
      if (
        options.title &&
        options.title.toLowerCase() === item.page.title.toLowerCase()
      )
        return item

      if (
        options.menu_title &&
        options.menu_title.toLowerCase() === item.page.title.toLowerCase()
      )
        return item

      if (options.id && options.id === item.page.id) return item
    })
  },

  findPage: (state) => (page) => {
    function findPage(page, items) {
      return items.find((item) => {
        if (item.page.id === page.id) return item
        if (item.children) return findPage(page, item.children)
      })
    }

    return findPage(page, state.menu)
  },
}

export const mutations = {
  SET_MENU(state, payload) {
    state.menu = payload
  },

  RESET_STATE(state) {
    const initial = defaultState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const actions = {
  async getMenu({ commit }, params) {
    function sortByIndex(items) {
      return items.sort((a, b) =>
        a.page.sort_index < b.page.sort_index
          ? -1
          : a.page.sort_index > b.page.sort_index
          ? 1
          : 0
      )
    }

    const menu = await this.$api.ltng.menu
      .getMenu({
        params: {
          account_id: this.$config.ltngId,
          sort_by: 'title',
          ...params,
        },
      })
      .then((res) => res.data)
      .then((menu) => {
        return sortByIndex(menu).map((item) => ({
          ...item,
          children: sortByIndex(item.children || []),
        }))
      })

    commit('SET_MENU', menu)
    return Promise.resolve(menu)
  },

  async resetState({ commit, dispatch }) {
    await commit('RESET_STATE')
    return await dispatch('getMenu')
  },
}
