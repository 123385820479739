import {
  ACCESS_REQUESTS_STATUS_APPROVED,
  ACCESS_REQUESTS_STATUS_DENIED,
  ACCESS_REQUESTS_STATUS_PENDING,
} from '~/constants'

const APPROVE_REQUEST = 'APPROVE_REQUEST'
const DENY_REQUEST = 'DENY_REQUEST'
const REMOVE_REQUEST = 'REMOVE_REQUEST'
const SET_REQUESTS = 'SET_REQUESTS'
const SET_LOADING = 'SET_LOADING'

const TTL = 3600

const initialState = () => ({
  requests: [],
  loading: false,
})

export const state = initialState

export const getters = {
  pendingRequests: (state) => {
    return state.requests.filter(
      (request) => request.status === ACCESS_REQUESTS_STATUS_PENDING
    )
  },
}

export const mutations = {
  [SET_REQUESTS](state, payload = []) {
    const now = new Date()

    state.requests = payload.map((request) => {
      const cachedRequest = state.requests.find(
        (cachedRequest) => cachedRequest.id === request.id
      )

      if (cachedRequest) {
        return {
          ...request,
          _expiry: cachedRequest._expiry,
        }
      } else {
        return {
          ...request,
          _expiry: now.getTime() + TTL,
        }
      }
    })
  },

  [APPROVE_REQUEST](state, id) {
    state.requests = state.requests.map((request) => {
      if (request.id === id) {
        request.status = ACCESS_REQUESTS_STATUS_APPROVED
      }

      return request
    })
  },

  [DENY_REQUEST](state, id) {
    state.requests = state.requests.map((request) => {
      if (request.id === id) {
        request.status = ACCESS_REQUESTS_STATUS_DENIED
      }

      return request
    })
  },

  [REMOVE_REQUEST](state, id) {
    state.requests = state.requests.filter((request) => request.id !== id)
  },

  [SET_LOADING](state, bool = false) {
    state.loading = bool
  },
}

export const actions = {
  setLoading({ commit }, bool) {
    commit(SET_LOADING, bool)
  },

  expireRequests({ commit, state }) {
    const now = new Date()

    state.requests.forEach((request) => {
      if (now.getTime() > request._expiry) {
        commit(REMOVE_REQUEST, request)
      }
    })

    return Promise.resolve()
  },

  async approveRequest({ commit }, request) {
    try {
      const response = await this.$api.ltng.accessRequests.update({
        ...request,
        status: ACCESS_REQUESTS_STATUS_APPROVED,
      })

      commit(APPROVE_REQUEST, request.id)

      return response
    } catch (error) {
      this.$bugsnag.notify(error)

      throw error
    }
  },

  async denyRequest({ commit }, request) {
    try {
      const response = await this.$api.ltng.accessRequests.update({
        ...request,
        status: ACCESS_REQUESTS_STATUS_DENIED,
      })

      commit(DENY_REQUEST, request.id)

      return response
    } catch (error) {
      this.$bugsnag.notify(error)

      throw error
    }
  },

  async getRequests({ commit, dispatch }) {
    commit(SET_LOADING, true)
    try {
      const { data } = await this.$api.ltng.accessRequests.getAll()

      await dispatch('expireRequests')

      commit(SET_REQUESTS, data.results)
      commit(SET_LOADING, false)

      return data
    } catch (error) {
      this.$bugsnag.notify(error)
      commit(SET_LOADING, false)

      throw error
    }
  },
}
