
export default {
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
  },

  computed: {
    computedClass() {
      return {
        'loader--center': this.align === 'center',
        'loader--left': this.align === 'left',
        'loader--right': this.align === 'right',
      }
    },
  },
}
