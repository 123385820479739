export default function ({ app: { router }, $config, $api, isDev }) {
  if (isDev) return
  if (!router) return

  router.afterEach((to) => {
    $api.ltng.analytics.record({
      path: window.origin + to.path,
    })
  })
}
