
export default {
  name: 'Sizing',

  mounted() {
    const fontSize = localStorage.getItem('1800-fontSize')

    if (fontSize) {
      document.documentElement.style.fontSize = fontSize + 'px'
    }
  },

  methods: {
    changeFontSize(type) {
      let fontSize = parseInt(
        window.getComputedStyle(document.documentElement, null).fontSize,
        0
      ) // parse the body font size as a number

      // @TODO Make better use of vuex state here
      if (type === 'increase') {
        if (fontSize === 18) return
        fontSize++ // increase the base font size
      } else {
        if (fontSize === 14) return
        fontSize-- // or decrease
      }

      document.documentElement.style.fontSize = fontSize + 'px' // set the body font size to the new value
      localStorage.setItem('1800-fontSize', fontSize)
    },

    resetFontSize() {
      document.documentElement.style.fontSize = null
      localStorage.removeItem('1800-fontSize')
    },

    onTranslateClick() {
      window.au5ton.showBanner = true
    },
  },
}
