
export default {
  props: {
    name: {
      type: String,
      default: 'name',
    },
    classname: {
      type: String,
      default: 'inputclass',
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Input Label',
    },
    arialabel: {
      type: String,
      default: 'Label',
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    error: {
      type: String,
      default: '',
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    option: {
      type: String,
      default: '',
    },

    cleave: {
      type: [Object, Boolean],
      default: false,
    },
  },
}
