export default function (axios) {
  return {
    getProducts(params) {
      return axios
        .get('/modules/transact/products', { params })
        .then((res) => res.data)
    },

    getProduct(id, params) {
      return axios
        .get(`/modules/transact/products/${id}`, params)
        .then((res) => res.data)
        .catch()
    },

    getProductTypes(params) {
      return axios
        .get(`/modules/transact/product-types`, params)
        .then((res) => res.data.results)
        .catch()
    },

    getProductVariants(productId, params = {}) {
      return axios
        .get(`/modules/transact/product-variants`, {
          params: {
            product_id: productId,
            ...params.params,
          },
          ...params,
        })
        .then((res) => res.data.results)
        .catch()
    },
  }
}
