import { addMetaTags } from '~/utils/addMetaTags'

export default function (context, inject) {
  inject('helpers', {
    cfImage: (imageId, variant = 'public') =>
      `https://imagedelivery.net/HLPjbC3XS29YPo5AOe4Zvg/${imageId}/${variant}`,

    addMetaTags,
  })
}
