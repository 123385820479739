import langmap from 'langmap'

export default function (context, inject) {
  inject('langmap', (langCode) => {
    // Adds Armenian 'hy' code
    langmap.hy = {
      nativeName: 'Հայերեն',
      englishName: 'Armenian',
    }

    // Adds Hmong 'hmn'
    langmap.hmn = {
      englishName: 'Hmong',
    }

    langmap.ps = {
      englishName: 'Pashto',
    }

    langmap.zn = {
      englishName: 'Chinese',
    }

    langmap.enes = {
      englishName: 'English/Spanish',
    }

    try {
      return langmap[langCode.toLowerCase()].englishName
    } catch (error) {
      return langCode
    }
  })
}
