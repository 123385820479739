import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=a938e320&scoped=true"
import script from "./Auth.vue?vue&type=script&lang=js"
export * from "./Auth.vue?vue&type=script&lang=js"
import style0 from "./Auth.vue?vue&type=style&index=0&id=a938e320&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a938e320",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonsButton: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/Buttons/Button.vue').default})
