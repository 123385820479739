import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=13a2c1c6&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=13a2c1c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a2c1c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterElementsSocialLinks: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/FooterElements/SocialLinks.vue').default,PageElementsStoreLinks: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/PageElements/StoreLinks.vue').default,FooterElementsCertification: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/FooterElements/Certification.vue').default})
