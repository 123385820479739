import ltng from '../api/ltng'
import resources from '../api/resources'

export default function (ctx, inject) {
  const ltngClient = ctx.$axios.create({
    baseURL: ctx.$config.ltngApi,
    params: {
      account_id: ctx.$config.ltngId,
    },
  })

  const resourcesClient = ctx.$axios.create({
    baseURL: ctx.$config.resourcesApi,
  })

  const ltngApi = ltng(ltngClient)
  const resourcesApi = resources(resourcesClient)

  if (ctx.$auth.loggedIn) {
    resourcesClient.setToken(ctx.$auth.strategy.token.get())
    ltngClient.setToken(ctx.$auth.strategy.token.get())
  }

  ltngClient.interceptors.response.use(
    (response) => response,
    async function (error) {
      const originalRequest = error.config

      if ([401].includes(error.response.status) && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          const { id_token: token } = await refreshHandler(ltngClient, ctx)

          ltngApi.analytics.record({
            auth: 'extension',
          })

          await Promise.all([
            ctx.$auth.strategy.token.set(token),
            ctx.$api.setToken(token),
          ])

          originalRequest.headers.Authorization = `Bearer ${token}`

          return ltngClient(originalRequest)
        } catch (error) {
          if (ctx.$auth.loggedIn) {
            ctx.store.dispatch('user/logout')
          }
        }
      }

      return Promise.reject(error)
    }
  )

  inject('api', {
    ltng: ltngApi,
    resources: resourcesApi,

    setToken: async (token) => {
      return await Promise.all([
        resourcesClient.setToken(token, 'Bearer'),
        ltngClient.setToken(token, 'Bearer'),
      ])
    },
  })
}

async function refreshHandler(axiosClient, context) {
  try {
    const { data } = await axiosClient.post(
      `https://${context.$config.auth0.domain}/oauth/token`,
      {
        grant_type: 'refresh_token',
        client_id: context.$config.auth0.clientId,
        refresh_token: context.$auth.strategy.refreshToken.get(),
      }
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
