import { render, staticRenderFns } from "./DisclaimerModal.vue?vue&type=template&id=7f98990f&scoped=true"
import script from "./DisclaimerModal.vue?vue&type=script&lang=js"
export * from "./DisclaimerModal.vue?vue&type=script&lang=js"
import style0 from "./DisclaimerModal.vue?vue&type=style&index=0&id=7f98990f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f98990f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormElementsInputsInputTextarea: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/FormElements/Inputs/InputTextarea.vue').default,FormElementsInputsInputCheckbox: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/FormElements/Inputs/InputCheckbox.vue').default,ButtonsButton: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/Buttons/Button.vue').default,ModalsModalCover: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/Modals/ModalCover.vue').default})
