// STATE
export const state = () => ({
  county: [],
})

// GETTERS
export const getters = {
  county(state) {
    return state.county
  },

  getSelectedLength: (state) => state.selected.length,

  getCart: (state) => state.cart,

  getCartStep: (state) => state.cartStep,

  getCartQuantity: (state) => {
    return state.cart.reduce((acc, item) => {
      return item.quantity + acc
    }, 0)
  },
}

// MUTATIONS
export const mutations = {
  SET_COUNTY(state, county) {
    state.county = county
  },
}

// ACTIONS
export const actions = {
  async getCounty({ commit }) {
    if (this.state.general.county.length === 0) {
      const county = await this.$api.resources.getCounty()
      commit('SET_COUNTY', county.data.results)
    }
  },
}
