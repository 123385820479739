export const actions = {
  async resetState({ dispatch }) {
    return await Promise.all([
      dispatch('transact/resetState', null, { root: true }),
      dispatch('menu/resetState', null, { root: true }),
      dispatch('cart/resetState', null, { root: true }),
      dispatch('user/resetState', null, { root: true }),
    ])
  },
}
