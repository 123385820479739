export default function (axios) {
  return {
    getUploads(params) {
      return axios.get('/modules/uploads', params)
    },

    getUpload(id, params) {
      return axios.get(`/modules/uploads/${id}`, params)
    },
  }
}
