export default {
  socialNetworks: [
    {
      title: 'Twitter',
      url: 'https://twitter.com/1800victims?s=21',
      icon: () => import('@/assets/svg/twitter.svg?inline'),
    },
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/CAVictimsResourceCenter',
      icon: () => import('@/assets/svg/facebook.svg?inline'),
    },
    {
      title: 'YouTube',
      url: 'https://www.youtube.com/channel/UCkm7l142HhVzToiurersElg',
      icon: () => import('@/assets/svg/youtube.svg?inline'),
    },
    {
      title: 'Instagram',
      url: 'https://www.instagram.com/1800victims/',
      icon: () => import('@/assets/svg/instagram.svg?inline'),
    },
  ],

  breakpoints: {
    sm: 768,
    md: 1280,
    lg: Infinity,
  },
}

export const addMetaTags = (options) =>
  Object.keys(options)
    .map((option) => {
      return [
        {
          hid: `twitter:${option}`,
          name: `twitter:${option}`,
          content: options[option],
        },
        {
          hid: `og:${option}`,
          name: `og:${option}`,
          content: options[option],
        },
        {
          hid: option,
          name: option,
          content: options[option],
        },
      ]
    })
    .flat()
