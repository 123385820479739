const defaultState = {
  navIsOpen: false,
  search: {
    query: '',
    section: 'all',
  },
  externalGuardOpen: false,
  externalUrl: '',
  fontSize: '',
  menu: null,
}

export const state = () => Object.assign({}, defaultState)

export const mutations = {
  TOGGLE_NAV(state) {
    state.navIsOpen = !state.navIsOpen
  },

  OPEN_NAV(state) {
    state.navIsOpen = true
    document.body.classList.add('menu-open')
  },

  CLOSE_NAV(state) {
    state.navIsOpen = false
    document.body.classList.remove('menu-open')
  },

  SET_SEARCH_QUERY(state, payload) {
    state.search.query = payload
  },

  SET_SEARCH_SECTION(state, section) {
    state.search.section = section
  },

  SET_EXTERNAL_GUARD(state, payload) {
    state.externalGuardOpen = payload
  },

  SET_EXTERNAL_URL(state, payload) {
    state.externalUrl = payload
  },

  INCREASE_FONTSIZE(state) {
    state.fontSize++
  },

  DECREASE_FONTSIZE(state) {
    state.fontSize--
  },
}

export const actions = {
  async toggleNav({ dispatch, state }) {
    if (state.navIsOpen) {
      await dispatch('closeNav')
    } else {
      await dispatch('openNav')
    }
  },

  async openNav({ commit }) {
    await commit('OPEN_NAV')
  },

  async closeNav({ commit }) {
    await commit('CLOSE_NAV')
  },

  async setSearchQuery({ commit }, payload) {
    await commit('SET_SEARCH_QUERY', payload)
  },

  async setSearchSection({ commit }, payload) {
    await commit('SET_SEARCH_SECTION', payload)
  },

  async openExternalGuard({ commit }, url) {
    commit('SET_EXTERNAL_GUARD', true)
    commit('SET_EXTERNAL_URL', url)

    return await Promise.resolve(url)
  },

  async closeExternalGuard({ commit }) {
    commit('SET_EXTERNAL_GUARD', false)
    commit('SET_EXTERNAL_URL', '')

    return await Promise.resolve()
  },
}
