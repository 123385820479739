
import { FadeTransition } from 'vue2-transitions'

export default {
  name: 'Button',

  components: {
    FadeTransition,
  },

  props: {
    text: {
      type: String,
      default: 'Button Text',
    },

    to: {
      type: String,
      default: undefined,
    },

    href: {
      type: String,
      default: undefined,
    },

    primary: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    peach: {
      type: Boolean,
    },
    abort: {
      type: Boolean,
    },
    altgreen: {
      type: Boolean,
    },
    grey: {
      type: Boolean,
    },
    black: {
      type: Boolean,
    },
    corpdarkblue: {
      type: Boolean,
    },
    corplightblue: {
      type: Boolean,
    },
    corpmediumblue: {
      type: Boolean,
    },
    corplightteal: {
      type: Boolean,
    },
    corpdarkteal: {
      type: Boolean,
    },
    corppeach: {
      type: Boolean,
    },
    corpdarkorange: {
      type: Boolean,
    },
    corplightorange: {
      type: Boolean,
    },
    danger: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass() {
      return {
        'btn--primary': this.primary,
        'btn--secondary': this.secondary,
        'btn--transparent': this.transparent,
        'btn--corp-darkblue': this.corpdarkblue,
        'btn--corp-mediumblue': this.corpmediumblue,
        'btn--corp-lightblue': this.corplightblue,
        'btn--corp-darkteal': this.corpdarkteal,
        'btn--corp-peach': this.corppeach,
        'btn--corp-darkorange': this.corpdarkorange,
        'btn--corp-lightorange': this.corplightorange,
        'btn--peach': this.peach,
        'btn--disabled': this.disabled,
        'btn--outlined': this.outlined,
        'btn--abort': this.abort,
        'btn--altgreen': this.altgreen,
        'btn--black': this.black,
        'btn--danger': this.danger,
        'btn--loading': this.loading,
        'btn--small': this.small,
      }
    },
  },

  methods: {
    onClick(e) {
      this.$emit('click', e)
    },
  },
}
