
import { mapGetters } from 'vuex'
import cartSVG from '~/assets/svg/cart.svg?inline'

export default {
  name: 'CartIndicator',
  components: {
    cartSVG,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters('cart', ['getCartItemCount']),

    computedClass() {
      return {
        'value--notEmpty': this.getCartItemCount > 1,
        'value--twoDigits': this.getCartItemCount >= 10,
        'value--threeDigits': this.getCartItemCount >= 100,
      }
    },
  },
}
