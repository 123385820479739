
export default {
  name: 'ModalCover',

  props: {
    centered: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modalStyle() {
      return {
        centered: this.centered,
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.handleKeyUp)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp)
  },

  methods: {
    handleKeyUp(event) {
      if (event.key !== 'Escape') return

      this.$emit('close')
    },
  },
}
