export default function (axios) {
  return {
    getCustomer(id, params) {
      return axios
        .get(`/modules/transact/customers/${id}`, params)
        .then((res) => res.data)
    },

    createCustomer(params) {
      return axios
        .post('/modules/transact/customers', params)
        .then((res) => res.data)
    },

    updateCustomer(id, body, params) {
      return axios
        .put(`/modules/transact/customers/${id}`, body, params)
        .then((res) => res.data)
    },
  }
}
