export default function (axios) {
  return {
    getUsers(params) {
      return axios.get('/modules/users', params)
    },

    postUsers(data, params) {
      return axios.post('/modules/users', data, params)
    },

    putUsers(id, data, params) {
      return axios.put(`/modules/users/${id}`, data, params)
    },
  }
}
