
export default {
  mounted() {
    window.au5ton.translateWidget(
      {
        pageLanguage: 'en',
        chunkSize: 3,
        endpoints: {
          supportedLanguages: 'https://modx.io/languages',
          translate: 'https://modx.io/translate',
        },
      },
      'libre-widget'
    )
  },
}
