import pages from './pages'
import forms from './forms'
import users from './users'
import transact from './transact'
import uploads from './uploads'
import menu from './menu'
import accessRequests from './accessRequests'
import analytics from './analytics'

export default function (axios) {
  return {
    pages: pages(axios),
    forms: forms(axios),
    users: users(axios),
    transact: transact(axios),
    menu: menu(axios),
    uploads: uploads(axios),
    accessRequests: accessRequests(axios),
    analytics: analytics(axios),
    $client: axios,
  }
}
