import { render, staticRenderFns } from "./HeaderLarge.vue?vue&type=template&id=5c567190&scoped=true"
import script from "./HeaderLarge.vue?vue&type=script&lang=js"
export * from "./HeaderLarge.vue?vue&type=script&lang=js"
import style0 from "./HeaderLarge.vue?vue&type=style&index=0&id=5c567190&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c567190",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderElementsAccessibilitySkipToMain: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/Accessibility/SkipToMain.vue').default,HeaderElementsLogo: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/Logo.vue').default,FormElementsSearchbox: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/FormElements/Searchbox.vue').default,HeaderElementsAccessibilitySizing: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/Accessibility/Sizing.vue').default,Auth: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/Auth/Auth.vue').default,HeaderElementsCartIndicator: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/CartIndicator.vue').default,HeaderElementsNavFull: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/NavFull.vue').default,Header: require('/home/travis/build/Polumathes/LTNG-1800-Frontend/components/HeaderElements/Header.vue').default})
